(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:groupReport
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="components">
       <file name="index.html">
        <group-report></group-report>
       </file>
     </example>
   *
   */
  angular
    .module('components')
    .directive('groupReport', groupReport);

  function groupReport() {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'components/group-report/group-report-directive.tpl.html',
      replace: false,
      controllerAs: 'groupReport',
      controller: 'GroupReportCtrl'
    };
  }
}());
